/**
 * easygreentracking-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1-rc.0.416+94c1813
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CreateTransferShipmentDto { 
    dhlPieceId: string;
    dhlCapsuleId: Array<string>;
    originFacility: number;
    destinationFacility: number;
    createdBy: string;
}