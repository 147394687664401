import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { CurrentUserDto, FacilityDto, ResetPasswordDto, UserDto, UserService } from '@api';
import { FacilityService } from '@common/service/facility.service';
import { UnsubscriberService } from '@common/service/unsubscriber.service';
import { FacilityStore } from '@common/store/facility.store';
import { AuthStore } from '../store/auth.store';
import { NoFacilitiesError } from '@common/errors/no-facilities-error';
import { BehaviorSubject, firstValueFrom, Observable, Subject, takeUntil, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthService extends UnsubscriberService implements OnDestroy {

    private currentUserRS = new BehaviorSubject<CurrentUserDto | undefined>(undefined);
    public readonly currentUser$: Observable<CurrentUserDto | undefined> = this.currentUserRS.asObservable();
    private readonly destroy$ = new Subject();
    private $isAdmin = false;

    private user$ = this.authStore.user$.pipe(
        tap(user => {
            this.setCurrentUser(user);
        }),
    );

    public constructor(
        private readonly authStore: AuthStore,
        private readonly httpClient: HttpClient,
        private readonly userService: UserService,
        private readonly facilityStore: FacilityStore,
        private readonly facilityService: FacilityService,
    ) {
        super();
        this.subscription = this.user$.pipe(takeUntil(this.destroy$)).subscribe();
    }

    public isAdmin(): boolean {
        return this.$isAdmin;
    }

    public getCurrentUser(): CurrentUserDto {
        const currentUser = this.currentUserRS.getValue();

        if (currentUser) {
            return currentUser;
        } else {
            throw new Error('User not found');
        }
    }

    private setCurrentUser(currentUser: CurrentUserDto | undefined): void {
        this.$isAdmin = currentUser?.admin ? currentUser.admin : false;
        this.currentUserRS.next(currentUser);
    }


    public async login(username: string, password: string): Promise<void> {
        this.authStore.reset();
        await this.facilityStore.reset();

        await this.authenticate(username, password);

        const defaultFacility = await this.getDefaultFacilityForCurrentUser();
        await this.facilityStore.setSelectedFacility(defaultFacility);

        await this.refreshCurrentUser();
    }

    public async logout(): Promise<void> {
        await this.httpClient.post('/api/logout', null, {
            responseType: 'text'
        }).toPromise();
        this.authStore.reset();
        await this.facilityStore.reset();
    }

    public async refreshCurrentUser(): Promise<void> {
        const user = await firstValueFrom(this.userService.getCurrentUser());
        this.authStore.setCurrentUser(user);
    }


    public get isSelectedFacilityServicePoint(): boolean {
        const selectedFacility = this.facilityStore.getSelectedFacility();
        if (selectedFacility != null) {
            return selectedFacility.type === FacilityDto.TypeEnum.P;
        } else {
            return false;
        }
    }

    public get isSelectedFacilityServiceCenter(): boolean {
        const selectedFacility = this.facilityStore.getSelectedFacility();
        if (selectedFacility != null) {
            return selectedFacility.type === FacilityDto.TypeEnum.F;
        } else {
            return false;
        }
    }

    public get isSelectedFacilityCustomer(): boolean {
        const selectedFacility = this.facilityStore.getSelectedFacility();
        if (selectedFacility != null) {
            return selectedFacility.type === FacilityDto.TypeEnum.C || selectedFacility.type === FacilityDto.TypeEnum.EC;
        } else {
            return false;
        }
    }

    public resetPassword(resetPasswordDto: ResetPasswordDto): Observable<UserDto> {
        return this.userService.setPasswordForCurrentUser(resetPasswordDto);
    }


    private async authenticate(username: string, password: string): Promise<void> {
        const form = new FormData();
        form.set('username', username);
        form.set('password', password);

        await this.httpClient.post(
            '/api/login',
            form,
            { responseType: 'text' }
        ).toPromise();
    }


    private async getDefaultFacilityForCurrentUser(): Promise<FacilityDto> {
        try {
            const facilities = await firstValueFrom(this.facilityService.getFacilitiesForCurrentUser());
            if (facilities[0]) {
                return facilities[0];
            }
        } catch (e: unknown) {
            throw new NoFacilitiesError();
        }

        throw new NoFacilitiesError();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(undefined);
        this.destroy$.complete();
    }

}
