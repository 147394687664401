import { Injectable } from '@angular/core';
import { FacilityDto } from '@api';


@Injectable({
  providedIn: 'root',
})
export class FacilityStore {
  private _facility?: FacilityDto;

  public async reset(): Promise<void> {
    await this.setSelectedFacility();
    this.setSelectedFacilityObject();
  }

  public async setSelectedFacility(facility?: FacilityDto): Promise<void> {
      return new Promise((resolve, reject) => {
          try {
              this._facility = Object.freeze(facility);
              this.setSelectedFacilityObject(facility);
              resolve();
          } catch (error: unknown) {
              reject(error);
          }
      });
  }

  public getSelectedFacility(): FacilityDto | undefined {
    return this.getSelectedFacilityObject();
  }

  private setSelectedFacilityObject(facility?: FacilityDto): void {
    if (facility) {
      localStorage.setItem('selectedFacility', JSON.stringify(facility));
    } else {
      localStorage.removeItem('selectedFacility');
    }
  }

  public getSelectedFacilityObject(): FacilityDto | undefined {
    const selectedFacility = localStorage.getItem('selectedFacility');
    if (selectedFacility){
      return JSON.parse(selectedFacility);
    } else {
      return undefined;
    }
  }
}
