/**
 * easygreentracking-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1-rc.0.416+94c1813
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ApiImporterLogErrorDto } from './apiImporterLogErrorDto';

export interface ApiImporterLogDto { 
    id: number;
    status: ApiImporterLogDto.StatusEnum;
    triggerType: ApiImporterLogDto.TriggerTypeEnum;
    importerType: ApiImporterLogDto.ImporterTypeEnum;
    startedAt: Date;
    updatedAt: Date;
    createdBy: string;
    apiImporterLogErrors: Array<ApiImporterLogErrorDto>;
}
export namespace ApiImporterLogDto {
    export type StatusEnum = 'STARTED' | 'FINISHED_SUCCESSFUL' | 'FINISHED_WITH_ERROR' | 'CONNECTION_ERROR';
    export const StatusEnum = {
        STARTED: 'STARTED' as StatusEnum,
        FINISHEDSUCCESSFUL: 'FINISHED_SUCCESSFUL' as StatusEnum,
        FINISHEDWITHERROR: 'FINISHED_WITH_ERROR' as StatusEnum,
        CONNECTIONERROR: 'CONNECTION_ERROR' as StatusEnum
    };
    export type TriggerTypeEnum = 'SCHEDULED' | 'MANUAL_FRONTEND';
    export const TriggerTypeEnum = {
        SCHEDULED: 'SCHEDULED' as TriggerTypeEnum,
        MANUALFRONTEND: 'MANUAL_FRONTEND' as TriggerTypeEnum
    };
    export type ImporterTypeEnum = 'DHL_BPI_API' | 'REELABLES_BLE_API';
    export const ImporterTypeEnum = {
        DHLBPIAPI: 'DHL_BPI_API' as ImporterTypeEnum,
        REELABLESBLEAPI: 'REELABLES_BLE_API' as ImporterTypeEnum
    };
}