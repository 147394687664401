/**
 * easygreentracking-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1-rc.0.416+94c1813
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CapsuleTypeDto } from './capsuleTypeDto';

export interface CapsuleDto { 
    dhlCapsuleId: string;
    statuses: Array<CapsuleDto.StatusesEnum>;
    usages: number;
    capsuleType: CapsuleTypeDto;
    capsuleIotId: string;
}
export namespace CapsuleDto {
    export type StatusesEnum = 'MISSING' | 'CLEAN' | 'LOST' | 'BLE_LOST' | 'DIRTY' | 'BROKEN' | 'DECOMMISSIONED';
    export const StatusesEnum = {
        MISSING: 'MISSING' as StatusesEnum,
        CLEAN: 'CLEAN' as StatusesEnum,
        LOST: 'LOST' as StatusesEnum,
        BLELOST: 'BLE_LOST' as StatusesEnum,
        DIRTY: 'DIRTY' as StatusesEnum,
        BROKEN: 'BROKEN' as StatusesEnum,
        DECOMMISSIONED: 'DECOMMISSIONED' as StatusesEnum
    };
}